<template>
  <BasePageContent>
    <BasePageHeader
      class="px-3"
      sectiontitle=""
      :title="title"
      :description="description"
    >
      <ul class="nk-block-tools g-1">
        <li class="nk-block-tools-opt">
          <a
            href="#"
            :title="'Add new ' + title_singular"
            class="btn btn-white btn-dim btn-outline-light"
            @click.prevent
          >
            <em class="icon ni ni-plus"></em>
            <span class="d-none d-md-inline">{{
              "Add new " + title_singular
            }}</span>
            <em class="dd-indc w3-text-theme icon ni ni-chevron-right"></em>
          </a>
        </li>
      </ul>
    </BasePageHeader>

    <div class="card-inner">
      <div class="px-1 mb-4 size12 w3-text-grey">
        Feature under construction
      </div>
      <InvoiceList
        id="invoiceList"
        title=""
        type="invoice"
        :invoices="Invoices"
      />
    </div>
  </BasePageContent>
</template>
<script>
import { ref } from "vue";
import InvoiceList from "@/components/ui/invoice/InvoiceList.vue";
export default {
  components: {
    InvoiceList,
  },
  setup() {
    const title = ref("Invoices");
    const title_singular = ref("invoice");
    const description = ref("Manage your invoices");
    const Invoices = [];
    return {
      title,
      title_singular,
      description,
      Invoices,
    };
  },
};
</script>