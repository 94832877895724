<template>
  <div v-show="Util.isEmpty(Invoice)" class="">
    <DataTable :id="ID" class="table-hover table-lg" :options="Options">
      <thead>
        <tr class="nk-tb-item nk-tb-head">
          <!-- <th class="nk-tb-col nk-tb-col-check">
            <div
              class="custom-control custom-control-sm custom-checkbox notext"
            >
              <input type="checkbox" class="custom-control-input" id="uid" />
              <label class="custom-control-label" for="uid"></label>
            </div>
          </th> -->
          <th class="nk-tb-col tb-col-mb">Invoice #</th>
          <th class="nk-tb-col" style="min-width: 200px">Customer</th>
          <th class="nk-tb-col tb-col-lg">Due Date</th>
          <th class="nk-tb-col">Amount</th>
          <th class="nk-tb-col tb-col-lg">Status</th>
          <th class="nk-tb-col nk-tb-col-tools text-right"></th>
        </tr>
      </thead>
      <tbody>
        <tr class="nk-tb-item" v-for="invoice in Invoices" :key="invoice.uuid">
          <!-- <td class="nk-tb-col nk-tb-col-check">
            <div
              class="custom-control custom-control-sm custom-checkbox notext"
            >
              <input
                type="checkbox"
                class="custom-control-input"
                :id="'uuid-' + index"
                :value="invoice.uuid"
              />
              <label
                class="custom-control-label"
                :for="'uuid-' + index"
              ></label>
            </div>
          </td> -->
          <td class="nk-tb-col tb-col-lg">{{ invoice.unit }}</td>
          <td
            @click.prevent="InvoiceDetail(invoice.uuid)"
            class="nk-tb-col cursor"
          >
            <div class="user-card">
              <div class="user-avatar bg-dim-primary d-none d-sm-flex">
                <img
                  :src="invoice.photo_url"
                  :alt="invoice.name"
                  class="rounded-circle"
                />
              </div>
              <div class="user-info">
                {{ invoice.name + " " }}
              </div>
            </div>
          </td>
          <td
            class="nk-tb-col tb-col-lg"
            v-html="Util.Currency(invoice.rate)"
          ></td>
          <td class="nk-tb-col">{{ invoice.unit }}</td>
          <td class="nk-tb-col tb-col-lg ellipsis">
            <a
              v-if="invoice.mobile"
              :href="'tel:' + invoice.mobile"
              target="_blank"
              class="d-block"
              >{{ invoice.mobile }}
            </a>
            <a
              v-if="invoice.telephone"
              :href="'tel:' + invoice.email"
              target="_blank"
              class="d-block"
              >{{ invoice.telephone }}
            </a>
          </td>
          <td class="nk-tb-col nk-tb-col-tools">
            <ul class="nk-tb-actions gx-1">
              <li class="nk-tb-action-hidden">
                <a
                  href="#"
                  class="btn btn-trigger btn-icon"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Wallet"
                >
                  <em class="icon ni ni-wallet-fill"></em>
                </a>
              </li>
              <li class="nk-tb-action-hidden">
                <a
                  href="#"
                  class="btn btn-trigger btn-icon"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Message Invoice"
                >
                  <em class="icon ni ni-mail-fill"></em>
                </a>
              </li>
              <li class="nk-tb-action-hidden">
                <a
                  href="#"
                  class="btn btn-trigger btn-icon"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Ban Invoice"
                >
                  <em class="icon ni ni-user-cross-fill"></em>
                </a>
              </li>
              <li>
                <div class="drodown">
                  <a
                    href="#"
                    class="dropdown-toggle btn btn-icon btn-trigger"
                    data-toggle="dropdown"
                    ><em class="icon ni ni-more-h"></em
                  ></a>
                  <div class="dropdown-menu dropdown-menu-right">
                    <ul class="link-list-opt no-bdr">
                      <li>
                        <a
                          title="Create New Invoice"
                          :data-uuid="invoice.uuid"
                          href="#"
                        >
                          <em class="icon fas fa-file-invoice"></em>
                          <span>New Invoice</span></a
                        >
                      </li>
                      <li>
                        <a
                          title="Create New Estimate"
                          :data-uuid="invoice.uuid"
                          href="#"
                        >
                          <em class="icon fas fa-file-alt"></em>
                          <span>New Sale Order</span></a
                        >
                      </li>
                      <li>
                        <a
                          title="Invoice Detail"
                          :data-uuid="invoice.uuid"
                          @click.stop.prevent="InvoiceDetail(invoice.uuid)"
                          href="#"
                        >
                          <em class="icon ni ni-eye"></em>
                          <span>View Detail</span></a
                        >
                      </li>
                      <li class="divider"></li>
                      <li>
                        <a
                          title="Edit"
                          :data-uuid="invoice.uuid"
                          @click.stop.prevent="EditInvoice(invoice.uuid)"
                          href="#"
                        >
                          <em class="icon fas fa-edit"></em>
                          <span>Edit Invoice</span></a
                        >
                      </li>
                      <li>
                        <a
                          title="Edit"
                          :data-uuid="invoice.uuid"
                          @click.stop.prevent="DeleteDetail(invoice.uuid)"
                          href="#"
                        >
                          <em class="icon fas fa-trash"></em>
                          <span>Delete Invoice</span></a
                        >
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
            </ul>
          </td>
        </tr>
      </tbody>
    </DataTable>
  </div>
</template>
<script>
import { ref, toRef } from "vue";
import { useStore } from "vuex";
import { v4 as uuidv4 } from "uuid";
import { Util } from "@/helpers/utilities";
import DataTable from "@/components/ui/DataTable.vue";
export default {
  components: {
    DataTable,
  },
  props: {
    id: {
      type: String,
    },
    invoices: {
      type: Array,
    },
    title: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "",
    },
  },

  setup(props) {
    const store = useStore();
    const ID = props.id ? props.id : uuidv4();
    const Invoices = toRef(props, "invoices");
    const Options = {
      pageLength: 25,
      responsive: false,
      order: [[2, "desc"]],
      aoColumnDefs: [
        {
          bSortable: false,
          aTargets: [0, 2, -1],
        },
      ],
    };
    const Invoice = {}; // computed(() => store.getters["invoices/invoice"]);
    const InvoiceUuid = ref("");
    const InvoiceDetail = (uuid) => {
      console.log(uuid);
      //   const invoice = Invoices.value.find((item) => item.uuid === uuid);
      //   store.dispatch("invoices/SetInvoice", invoice);
    };
    const OnSaveInvoice = () => {
      CloseInvoiceDetail();
    };
    const EditInvoice = (uuid) => {
      //   store.dispatch(
      //     "invoices/SetInvoice",
      //     Invoices.value.find((custumer) => custumer.uuid === uuid)
      //   );
      InvoiceUuid.value = uuid;
    };
    const DeleteDetail = (uuid) => {
      console.log("DeleteDetail", uuid);
    };
    const OpenInvoiceUpdateModal = ref(false);
    const CancelUpdateInvoice = () => {
      CloseInvoiceDetail();
    };
    const CloseInvoiceDetail = () => {
      InvoiceUuid.value = "";
      //   store.dispatch("invoices/UnsetInvoice");
    };

    return {
      store,
      ID,
      Invoice,
      Invoices,
      Util,
      Options,
      InvoiceDetail,
      OnSaveInvoice,
      InvoiceUuid,
      EditInvoice,
      DeleteDetail,
      OpenInvoiceUpdateModal,
      CloseInvoiceDetail,
      CancelUpdateInvoice,
    };
  },
};
</script>